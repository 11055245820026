import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

function Footer(props) {
  let { props2, prodList, gotoMyAccount } = props;

  let history = useHistory();



  const gotoProd = (field) => {
    // window.scrollTo({
    //   top: 0,
    //   behavior: "smooth",
    // });
    window.scrollTo(0, 0);
    props2.history.push(`/assessment/productdetails/team/` + field);
    // window.scrollTo({
    //   top: 0,
    //   behavior: "smooth",
    // });
    window.scrollTo(0, 0);
    window.location.reload();
  };

  return (
    <footer className="bg-white">
      <div className="px-4 pt-16 mx-auto 2xl:w-9/12 xl:w-11/12 xl:px-0 sm:px-6 ">
        <div className="grid grid-cols-1 col-span-1 gap-6 lg:grid-cols-4">
          <div>
            <h1 className="text-lg font-semibold text-[#3D405B] ">
              TalentPlusStrengths
            </h1>
            <nav className="flex flex-col mt-4 space-y-2 text-sm text-[#3D405B]">
              <a
                href="/#/assessment/how-talentplus-strength-works"
                className="cursor-pointer hover:text-[#2196F3]"
              >
                How TalentPlusStrengths Works{" "}
              </a>
              <a
                href="/#/assessment/the-4-domains-and-22-themes"
                className="cursor-pointer hover:text-[#2196F3]"
              >
                The 4 Domains and, 22 themes
              </a>
              <a
                href="/#/assessment/teen-talent"
                className="cursor-pointer hover:text-[#2196F3]"
              >
                The 6 Domains and 18 themes
              </a>
            </nav>
          </div>

          <div>
            <h1 className="text-lg font-semibold text-[#3D405B] ">Reports</h1>
            <nav className="flex flex-col mt-4 space-y-2 text-sm text-[#3D405B]">
              {prodList && prodList.length > 0
                ? prodList.map((item) => (
                  <a
                    key={item.id}
                    // href={`/#/assessment/${item.id}`}
                    onClick={() => gotoProd(item.id)}
                    className="cursor-pointer hover:text-[#2196F3]"
                  >
                    {item.name}
                  </a>
                ))
                : null}
            </nav>
          </div>

          <div>
            <h1 className="text-lg font-semibold text-[#3D405B] ">
              My Account
            </h1>
            <nav className="flex flex-col mt-4 space-y-2 text-sm text-[#3D405B]">
              <div
                onClick={() => gotoMyAccount("profile-summary")}
                className="cursor-pointer hover:text-[#2196F3]"
              >
                Profile Summary
              </div> 
              <div
                onClick={() => gotoMyAccount("my-orders")}
                className="cursor-pointer hover:text-[#2196F3]"
              >
                My Orders
              </div>
              <div
                onClick={() => gotoMyAccount("assessment-code")}
                className="cursor-pointer hover:text-[#2196F3]"
              >
                Assessment Code
              </div>
            </nav>
          </div>
          <div className="">
            <h1 className="text-lg font-semibold text-[#3D405B] ">Connect</h1>
            <p className="max-w-xs mt-4 text-sm text-[#3D405B]">
              Email: connect@happyplus.in
            </p>
            <p className="max-w-xs mt-4 text-sm text-[#3D405B]">
              Address: HappyPlus Consulting Pvt. Ltd. Goregaon (e), Mumbai
              400065
            </p>
          </div>
        </div>

        <div className="flex md:flex-row flex-col gap-4 justify-between items-center py-6 mt-8 text-sm text-center text-[#3D405B] border-t capitalize">
          <div className="flex items-center space-x-2">
            <span className="underline" onClick={() => history.push("/assessment/terms-and-conditions")}>
              Terms & conditions
            </span>{" "}
            <span>|</span>
            <span className="underline" onClick={() => history.push("/assessment/privacy-policy")}>
              {" "}
              Privacy Policy
            </span>
          </div>
          <p className="">All Rights Reserved | Copyright © TalentPlusStrengths.</p>
        </div>
      </div>
    </footer>
  );
}
export default Footer;
